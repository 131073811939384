/****************************** HEAP CONSTANTS (passed via statsig) ********************************/
// Follow naming standards: https://eventbrite.atlassian.net/wiki/spaces/DEV/pages/15423275681/What+are+the+best+practice+naming+conventions+for+Heap+events

// General events
export const HEAP_LOCATION_STRING = 'Home - All';
export const HEAP_ALL_EVENTS_CLICK = `${HEAP_LOCATION_STRING} - Click - Event(s)`;
export const HEAP_FEATURED_EVENTS: `${string} - ${string}` = `${HEAP_LOCATION_STRING} - Click - Featured Events`;
export const HEAP_SCROLLED_TO_BOTTOM = `${HEAP_LOCATION_STRING} - Scroll End - All`;
export const HEAP_SEE_MORE_BTN_CLICK = `${HEAP_LOCATION_STRING} - Click - See More`;
export const HEAP_TAB_CLICK = `${HEAP_LOCATION_STRING} - Click - Tab(s)`;
export const HEAP_VIEW_FOR_YOU_TAB = `${HEAP_LOCATION_STRING} - View - For You Tab`;
export const HEAP_HOMEPAGE_VIEWED = `${HEAP_LOCATION_STRING} - View - Page`;
export const HEAP_SEARCH_BOX_IN_HEADER_CLICK = 'Homepage - Search Box - Click';
export const HEAP_STICKY_LOCATION_PILL_CLICK =
    'Homepage - Sticky Location Pill - Click';
export const STATSIG_HOME_ANY_CLICK = `${HEAP_LOCATION_STRING} - Click - Any`;
export const STATSIG_HOME_CLICK_TRENDING_CATEGORY =
    'Homepage - All - Click - Trending Category';
export const HEAP_PAGE_AREA = 'homepage';
export const HOMEPAGE_HOLIDAY_FEATURED: `${string} - ${string}` = `${HEAP_LOCATION_STRING} - Click - Holiday Featured`;
export const HOMEPAGE_SEASONAL_VALENTINE: `${string} - ${string}` = `${HEAP_LOCATION_STRING} - Click - Seasonal Valentine`;
export const HOMEPAGE_SEASONAL_SPD: `${string} - ${string}` = `${HEAP_LOCATION_STRING} - Click - Seasonal SPD`;
export const HOMEPAGE_LIGHTHOUSE_EVENTS: `${string} - ${string}` = `${HEAP_LOCATION_STRING} - Click - Lighthouse Events`;
export const WEEKEND_GUIDE_EVENT_CLICK = `${HEAP_LOCATION_STRING} - Click - Weekend Event Card`;
export const HOMEPAGE_SEASONAL_FEATURED: `${string} - ${string}` = `${HEAP_LOCATION_STRING} - Click - Seasonal Featured Event`;
export const HOMEPAGE_SEASONAL_CTR_CLICK: `${string} - ${string}` = `${HEAP_LOCATION_STRING} - Click - Seasonal Module Arrows`;
export const HOMEPAGE_PAID_CAROUSEL_EVENT: `${string} - ${string}` = `${HEAP_LOCATION_STRING} - Click - Paid Carousel Event`;
export const HOMEPAGE_PAID_EVENTS_CAROUSEL_CTR_CLICK: `${string} - ${string}` = `${HEAP_LOCATION_STRING} - Click - Paid Events Module Arrows`;
export const HOMEPAGE_EVENTS_NEAR_YOU_CAROUSEL_EVENT: `${string} - ${string}` = `${HEAP_LOCATION_STRING} - Click - Events Near You Carousel Event`;
export const HOMEPAGE_EVENTS_NEAR_YOU_CAROUSEL_CTR_CLICK: `${string} - ${string}` = `${HEAP_LOCATION_STRING} - Click - Events Near You Module Arrows`;
export const HOMEPAGE_DATING_CAROUSEL: `${string} - ${string}` = `${HEAP_LOCATION_STRING} - Click - Dating Carousel Event`;
export const HOMEPAGE_POPULAR_EVENT_CARD: `${string} - ${string}` = `${HEAP_LOCATION_STRING} - Click - Popular Event Card`;
// Buckets
export const HEAP_BUCKET_ONLOAD = 'PersonalizationBucketLoad';
export const HEAP_BUCKET_VIEW = 'PersonalizationBucketView';

// Horizonal Scrolling Buckets
export const HEAP_CAROUSEL_SCROLLED_TO_END = `${HEAP_LOCATION_STRING} - Scroll End - Carousel(s)`;

// Personalization Filters
export const HEAP_DATE_TEXT_FILTER_SELECTED = `${HEAP_LOCATION_STRING} - Click - Date Text Filter`;
export const HEAP_DATE_CUSTOM_FILTER_SELECTED = `${HEAP_LOCATION_STRING} - Click - Date Custom Filter`;
export const HEAP_DATE_FILTER_RESET = `${HEAP_LOCATION_STRING} - Click - Date Filter Reset`;

export enum HomeEngagementExperimentTrackingLocations {
    TAB = 'Tab',
    FOR_YOU = 'For You',
    CITY_CTA = 'City CTA',
    TRENDING = 'Trending Category',
}

export const STATSIG_BUCKET_CARD_CLICK =
    'Homepage - All - Click - Discover Bucket Card';
export const HEAP_BUCKET_CARD_ID = 'seasonal-bucket';

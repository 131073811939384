import {
    EVENTBRITE_CATEGORY,
    EVENTBRITE_CATEGORY_TO_REFINEMENT,
    EVENTBRITE_FORMAT,
    EVENTBRITE_FORMAT_TO_REFINEMENT,
    EVENTBRITE_SUB_CATEGORY,
    FormattedSubCategory,
    FORMATTED_SUBCATEGORY_MAP,
} from '@eventbrite/categories';

const CATEGORY_AND_FORMAT_MAPS: Record<
    string,
    Record<string, string> | Record<string, FormattedSubCategory>
> = {
    [EVENTBRITE_CATEGORY]: EVENTBRITE_CATEGORY_TO_REFINEMENT,
    [EVENTBRITE_SUB_CATEGORY]: FORMATTED_SUBCATEGORY_MAP,
    [EVENTBRITE_FORMAT]: EVENTBRITE_FORMAT_TO_REFINEMENT,
};

const TAG_TYPE_PREFIXES: Record<string, string> = {
    [EVENTBRITE_CATEGORY]: 'category',
    [EVENTBRITE_SUB_CATEGORY]: 'sub_category',
    [EVENTBRITE_FORMAT]: 'format',
};

export const categoryCodeToReadableTag = (
    tagCode: string,
): string | undefined => {
    const type: string = tagCode.split('/')[0];
    if (type in CATEGORY_AND_FORMAT_MAPS) {
        const selectedMap = CATEGORY_AND_FORMAT_MAPS[type];
        const maybeReadableTag = selectedMap[tagCode];
        if (!maybeReadableTag) {
            return tagCode;
        }
        return typeof maybeReadableTag === 'string'
            ? (maybeReadableTag as string)
            : (maybeReadableTag as FormattedSubCategory).refinement;
    }
    return undefined;
};

export const getFormattedTagWithType = (
    tagCode: string | string[] | undefined,
): string[] | undefined => {
    if (tagCode === undefined) {
        return undefined;
    }

    if (Array.isArray(tagCode)) {
        return getFormattedTagsWithType(tagCode);
    }

    return geTagInArrayIfApplicable(tagCode, []);
};

export const getFormattedTagsWithType = (tagCodes: string[]): string[] => {
    return tagCodes.reduce((acc: string[], tagCode: string) => {
        return geTagInArrayIfApplicable(tagCode, acc);
    }, []);
};

const geTagInArrayIfApplicable = (tagCode: string, acc: string[]): string[] => {
    if (!tagCode) {
        return acc;
    }

    const type: string = tagCode.split('/')[0];
    const maybeTag = categoryCodeToReadableTag(tagCode);
    if (maybeTag) {
        acc.push(`${retrievePrefixByTagType(type)}:${maybeTag}`);
    }
    return acc;
};

const retrievePrefixByTagType = (tagType: string): string => {
    return TAG_TYPE_PREFIXES[tagType] || '';
};
